<template>
  <section v-loading.fullscreen.lock="loading" :element-loading-text="loadingText"  element-loading-spinner="el-icon-loading"  class="top-navbar" id="topBarHeader" :style="`background: ${loginPageColor} !important;`">
    <!-- System Idle code start  */  -->
    <span v-if="this.brandingInfo &&
      this.brandingInfo.session_info &&
      this.brandingInfo.session_info.timeOut
      ">
      <v-idle style="display: none" @idle="onidle" @remind="onremind" :loop="true" :reminders="[60, 90]" :wait="10"
        :duration="this.brandingInfo.session_info.timeOutSeconds * 60" :events="events" />
    </span>
    <!-- System Idle code end   -->

    <div>
      <el-row type="flex" align="middle">
        <el-col :span="4">
          <div class="top-navbar-left-menu">
            <ul>
              <li v-if="!getIsMobile">
                <div class="company-logo">
                  <a href="/dashboard">
                    <img v-if="this.getSingleCompanyLogo" :src="logo" alt="Nimble logo"
                      style="max-height: 36px; max-width: 140px" />
                    <template v-else>
                      <img v-if="appSetting.APPLICATION_TYPE == 'ESIGNS'" src="@/assets/img/logo-white.svg"
                        alt="Nimble Logo" class="img-fluid" />
                      <img v-else src="@/assets/img/Logo-Eagle.png" alt="Logo" class="img-fluid" />

                    </template>

                  </a>
                </div>
              </li>
              <li>
                <div class="toggle-menu-animation">
                  <div v-if="isHorMenu" id="burger" :class="getIsOpen ? 'active' : ''" @click="sendMessageToParent">
                    <button type="button" class="burger-button" title="Menu">
                      <span class="hidden">Toggle menu</span>
                      <span class="burger-bar burger-bar--1"></span>
                      <span class="burger-bar burger-bar--2"></span>
                      <span class="burger-bar burger-bar--3"></span>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <!-- <div class="company-logo" v-if="getIsMobile">
            <a href="/dashboard">
              <img v-if="this.getSingleCompanyLogo" :src="logo" alt="Nimble logo" class="img-fluid" />
              <img v-else src="@/assets/img/logo-white.svg" alt="Nimble logo" class="img-fluid" />
            </a>
          </div> -->
        </el-col>
        <el-col :md="20">
          <div class="top-navbar-right-menu">
            <ul class="logout-dropdown">
              <li>
                <div class="workspace-wrapper" v-loading="loading">
                  <el-col v-if="!isExist">
                    <el-select v-loading="selectLoad" v-model="currentWorkspaceId" placeholder="Workspace"
                      @change="ChangeAccount" filterable remote>
                      <el-option v-for="(company, index) of userWorkspaceList" :key="index + '_workspace'"
                        :label="company.company_id.name" :value="company.company_id._id"></el-option>
                      <!-- <i v-if="company.company_id.is_cloned" class="el-icon-caret-right"></i>{{ company.company_id.name }}</el-option> -->
                    </el-select>
                  </el-col>
                </div>
              </li>
              <li v-if="isWorkspaceIsBusiness(this.getActiveWorkspace)">
                <div class="workspace-wrapper" v-if="globalFilterExists" v-loading="loading">
                  <el-select :placeholder="globalFilterEntity && globalFilterEntity.name
                      ? 'Select ' + globalFilterEntity.name
                      : 'Select '
                    " v-model="globalFilter" class="input-text" :loading="globalFilterLoading"
                    @change="(changedValue) => loadMoreEntityData(changedValue)" clearable
                    :remote-method="searchEntityData" remote filterable loading-text="Loading...">
                    <el-option v-for="(data, i) in allGlobalFilterEntityData || []" :key="'entity_' + i + '_' + data._id"
                      :value="data._id" :label="getEntityDataLabel(globalFilterEntity, data, i)">
                    </el-option>
                    <el-option value="LOADMORE" label="Load more..." v-if="!isSearching && globalFilterHasMoreData">
                    </el-option>
                  </el-select>
                </div>
                <div v-else class="workspace-wrapper" v-loading="loading">
                  <el-col v-if="!isExist">
                    <el-select v-model="activeRole" placeholder="Role" @change="changeRole">
                      <el-option v-for="(item, index) in assignedRoles" :key="index + '_userType'"
                        :label="item.role_title" :value="item.userType_id">
                        <span style="float: left"> {{ item.role_title }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">
                          {{ item.userType_name }}</span>
                      </el-option>
                    </el-select>
                  </el-col>
                </div>
              </li>
              <li v-if="this.planDetails.diff">
                <div v-if="!getIsMobile" class="notification-wrapper" @click="toggleChatPopup">
                  <div class="number-on-bell chat" v-if="getChatCountUnOpened > 0">
                    {{ getChatCountUnOpened }}
                  </div>
                  <div class="icon-block">
                    <div class="icon user-image">
                      <img src="../assets/img/topbaricons/Chat.svg" alt="chat-image" class="img-fluid chat" />
                    </div>
                  </div>
                </div>
                <div class="notification-popup" v-if="isChatPopupShow && getChats && getChats.length"
                  v-click-outside="hideChatPopup">
                  <ul class="list-group list-group-flush" v-if="getChats && getChats.length">
                    <li class="notifications-top-row">
                      <span>Chats( {{ getChatsUnReadCnt }})</span>
                      <a v-if="getChatsUnReadCnt && getChatsUnReadCnt > 0" @click="markAsReadChats">Mark all as read</a>
                    </li>
                    <li class="list-group-item notifcation-item" v-for="(item, index) in getChats" :key="index + '_chart'"
                      @click="goToChats(item._id, true)">
                      <div class="item-avatar">
                        {{ item | getUserNameAvatar }}
                        <div class="unread" v-if="!item.is_read"></div>
                      </div>
                      <div class="item-body">
                        <p class="mb-0 text-secondary">
                          <span class="name">
                            {{ item | getUserFullName }}</span>
                          <span class="commented-on">commented on</span>
                          <span class="doc-name"> {{ item.title }}</span>
                          {{ item.text | truncate(20, "...") }}
                        </p>
                        <span class="date-time text-dark fw-medium pl-0 opacity-80">
                          {{ item.created_at | getFormattedDateTypeThree }}
                        </span>
                      </div>
                    </li>
                  </ul>
                  <div class="notifications-bottom-row">
                    <a @click="viewAllChats">View all messages</a>
                  </div>
                  <!-- </el-scrollbar> -->
                </div>
              </li>
              <li v-if="this.planDetails.diff">
                <div v-if="!getIsMobile" class="notification-wrapper" @click="toggleNotificationPopup">
                  <div class="number-on-bell" v-if="getNotificationCountUnOpened > 0">
                    {{ getNotificationCountUnOpened }}
                  </div>
                  <div class="icon-block">
                    <div class="icon user-image">
                      <img src="../assets/img/topbaricons/Notification.svg" alt="notification-image" class="img-fluid" />
                    </div>
                  </div>
                </div>
                <div class="notification-popup" v-if="isNotificationPopupShow &&
                  getNotifications &&
                  getNotifications.length
                  " v-click-outside="hideNotificationPopup">
                  <!-- <div class="arrow">
                  </div>-->
                  <!-- <el-scrollbar wrap-style="max-height: 350px;"> -->
                  <ul class="list-group list-group-flush" v-if="getNotifications && getNotifications.length">
                    <li class="notifications-top-row">
                      <span>Notifications({{ getNotificationsUnReadCnt }})</span>
                      <a @click="markAsReadActivities">Mark all as read</a>
                    </li>
                    <li class="list-group-item notifcation-item" v-for="(item, index) in getNotifications"
                      :key="index + '_notification'" @click="goToDocView(item)">
                      <div class="item-avatar">
                        {{ item.user_id | getUserNameAvatar }}
                        <div class="unread" v-if="!unmark(item)"></div>
                      </div>
                      <div class="item-body">
                        <p class="mb-0 text-secondary">
                          <span class="name">
                            {{ item.user_id | getUserFullName }}</span>
                          {{ item.description }}
                          <!-- <span class="text-danger fw-medium">Offer Letter Doc</span> -->

                          <span class="doc-name" v-if="item.employee_document_id">
                            {{ item.employee_document_id.name }}</span>
                          <span class="doc-name" v-else>&nbsp;</span>
                        </p>
                        <span class="date-time text-dark fw-medium pl-0 opacity-80">
                          {{ item.created_at | getFormattedDateTypeThree }}
                        </span>
                      </div>
                    </li>
                  </ul>
                  <div class="notifications-bottom-row">
                    <a @click="viewAllNotifications">View all notifications</a>
                  </div>
                  <!-- </el-scrollbar> -->
                </div>
              </li>
              <li v-if="this.planDetails.diff &&isWorkspaceIsBusiness(this.getActiveWorkspace)">
                <div class="notification-wrapper" @click="toggleAppNotificationPopup">
                  <div class="number-on-bell" v-if="totalUnreadNotificationsCount> 0">
                    {{ totalUnreadNotificationsCount }}
                  </div>
                  <div class="icon-block">
                    <div class="icon user-image">
                      <img src="../assets/img/topbaricons/App2.svg" alt="notification-image" class="img-fluid" />
                    </div>
                  </div>
                </div>
                <div class="notification-popup notification-popup2" 
                  v-if="isAppNotificationPopupShow" 
                  v-click-outside="hideAppNotificationPopup">
                  <el-scrollbar wrap-style="max-height: 450px;">
                  <ul class="list-group list-group-flush">
                    <li class="notifications-top-row first-row-select">
                      <div style="display: flex; align-items: center;">
                        <el-select 
                        v-model="selectedNotificationType" 
                        @change="fetchAppNotifications()" 
                        size="mini" 
                        style="margin-right:1%;">
                          <el-option 
                          v-for="(e) of appNotificationTypes" 
                          :key="e.value" 
                          :label="e.name" 
                          :value="e.value">
                          </el-option>
                        </el-select>
                        <el-input 
                        size="mini" 
                        style="width:50%;"
                        placeholder="Search" 
                        v-model="notification_search_string"
                        clearable
                        >
                        </el-input>
                      </div>
                    </li>
                    <div v-loading="appNotificationsLoading" element-loading-text="Fetching Logs">
                    <li class="notifications-top-row">
                      <span>Notifications({{unreadNotificationsCount || 0}}){{allNotificationsCount}}</span>
                      <div>
                      <a @click="markAppNotificationsAsReadChats">Mark all as read</a>
                      <el-switch
                        v-model="isUnreadOnly"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-value="UNREAD"
                        inactive-value="ALL"
                        @change="fetchAppNotifications()"
                      >
                      </el-switch>
                      </div>
                    </li>
                    <li class="list-group-item notifcation-item" v-for="(item, index) in allAppNotifications"
                      :key="index + '_notification'" @click="handleNotificationClick(item)">
                      <div class="item-avatar">
                        {{getNotificationTypeAvatar(item.type)}}
                        <div class="unread" v-if="!item.is_read"></div>
                      </div>
                      <div class="item-body">
                        <p class="mb-0 text-secondary">
                            <span v-html="getAppNotificationDescription(item)"></span>
                        </p>
                        <span class="date-time text-dark fw-medium pl-0 opacity-80">
                          {{ item.created_at | getFormattedDateTypeThree }}
                        </span>
                      </div>
                    </li>
                    <li class="load-more-element" 
                        @click="loadMoreLogs()"
                        v-if="allNotificationsCount > totalPageLogs" v-loading="LoadMoreLoading">
                      <div class="load-more">
                        <a >Load More...</a>
                      </div>
                    </li>
                    <li v-if="allAppNotifications && !allAppNotifications.length" class="list-group-item notifcation-item">
                      <div class="item-avatar">
                        ND
                      </div>
                      <div class="item-body">
                        <p class="mb-0 text-secondary">
                            <span>
                              You have no notifications to show 
                            </span>
                        </p>
                        <span class="date-time text-dark fw-medium pl-0 opacity-80">
                          -
                        </span>
                      </div>
                    </li>
                    </div>
                  </ul>
                  </el-scrollbar>
                  <!-- <div class="notifications-bottom-row">
                    <span>Total ({{totalUnreadNotificationsCount || 0}}){{totalNotificationsCount||0}}</span>
                  </div> -->
                </div>
              </li>
              <li>
                <el-dropdown>
                  <el-button>
                    <div class="icon-block">
                      <div class="icon user-image profile-image">
                        <img v-if="getAuthenticatedUser &&
                          getAuthenticatedUser.avatar &&
                          getAuthenticatedUser.avatar.length > 20
                          " :src="getAuthenticatedUser.avatar" alt="user-image" class="img-fluid" />

                        <img v-else src="@/assets/img/avatar.svg" alt="user-image" class="img-fluid" />
                      </div>
                      <div class="icon-text" v-if="!getIsMobile">
                        {{ getAuthenticatedUser | getUserFullName }}
                        <span class="el-icon-arrow-down text-white"></span>
                      </div>
                      <div class="icon-text" v-else>
                        {{ getAuthenticatedUser | getUserFullNameShortened }}
                        <span class="el-icon-arrow-down text-white"></span>
                      </div>
                    </div>
                    <template v-if="isWorkspaceIsBusiness(this.getActiveWorkspace)">
                      <p class="user-role">
                        <span v-if="this.getAuthenticatedUser &&
                          this.getAuthenticatedUser.activeRole &&
                          this.getAuthenticatedUser.activeRole.userType_id
                          ">
                          {{
                            this.getAuthenticatedUser.activeRole.userType_name
                          }}</span>
                        <span v-else> {{ getUserType.name }}</span>
                      </p>
                    </template>
                    <template v-else>
                      <p class="user-role">
                        <span>INDIVIDUAL</span>
                      </p>
                    </template>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <router-link to="/profile">
                      <el-dropdown-item>
                        <i class="el-icon-user"></i> Profile
                      </el-dropdown-item>
                    </router-link>

                    <router-link to="/profilesettings" v-if="this.planDetails && this.planDetails.diff">
                      <el-dropdown-item>
                        <i class="el-icon-setting"></i> Settings
                      </el-dropdown-item>
                    </router-link>
                    <router-link to="/configuration" v-if="isWorkspaceIsBusiness(this.getActiveWorkspace) && this.isOwner() && this.planDetails && this.planDetails.diff">
                      <el-dropdown-item>
                        <i class="fa fa-gg"></i>Integrations
                      </el-dropdown-item>
                    </router-link>
                    <router-link v-if="isWorkspaceIsBusiness(this.getActiveWorkspace) &&
                      checkPermissionsCategory('companyUsers') &&
                      this.planDetails.diff
                      " to="/company/users">
                      <el-dropdown-item>
                        <i class="el-icon-s-custom"></i>Company Users
                      </el-dropdown-item>
                    </router-link>

                    <!-- v-if="
                        checkPermissionsCategory('formTempaltes') &&
                        this.isWorkspaceIsBusiness(this.getActiveWorkspace)
                      " -->
                    <!-- v-if="
                      checkPermissionsCategory('entities') &&
                      this.isWorkspaceIsBusiness(this.getActiveWorkspace)
                    " -->
                    <!-- v-if="
                    checkPermissionsCategory('globalVariables') &&
                    this.isWorkspaceIsBusiness(this.getActiveWorkspace)
                  " -->
                    <router-link v-if="this.isWorkspaceIsBusiness(this.getActiveWorkspace) &&
                      checkPermissionByPermissionName('viewFormTemplates') &&
                      this.planDetails.diff
                      " to="/template">
                      <el-dropdown-item>
                        <img src="@/assets/img/sideMenu/inactive_icons/Form_Inactive.svg" alt="menu"
                          style="height: 12px; width: 14px; margin-right: 2px" />
                        Form Template
                      </el-dropdown-item>
                    </router-link>
                    <router-link v-if="this.isWorkspaceIsBusiness(this.getActiveWorkspace) &&
                      checkPermissionByPermissionName('viewEmailTemplate') &&
                      this.planDetails.diff
                      " to="/email-template">
                      <el-dropdown-item>
                        <img src="@/assets/img/sideMenu/inactive_icons/Form_Inactive.svg" alt="menu"
                          style="height: 11px; width: 12px" />
                        Email Templates
                      </el-dropdown-item>
                    </router-link>
                    <router-link v-if="this.isWorkspaceIsBusiness(this.getActiveWorkspace) &&
                      checkPermissionByPermissionName('viewEntities') &&
                      this.planDetails.diff
                      " to="/entity">
                      <el-dropdown-item>
                        <img src="@/assets/img/sideMenu/inactive_icons/Entities Inactive.svg" alt="menu"
                          style="height: 12px; width: 12px; margin-right: 2px" />
                        Entities
                      </el-dropdown-item>
                    </router-link>
                    <router-link v-if="this.isWorkspaceIsBusiness(this.getActiveWorkspace) &&
                      checkPermissionByPermissionName('viewEntities') &&
                      this.planDetails.diff
                      " to="/entity-views">
                      <el-dropdown-item>
                        <img src="@/assets/img/sideMenu/inactive_icons/Entities Inactive.svg" alt="menu"
                          style="height: 12px; width: 12px; margin-right: 2px" />
                        Entities Views
                      </el-dropdown-item>
                    </router-link>
                    <!-- v-if="this.isWorkspaceIsBusiness(this.getActiveWorkspace) &&checkPermissionByPermissionName('viewGlobalVaribales') && this.planDetails.diff" -->
                    <router-link v-if="this.isWorkspaceIsBusiness(this.getActiveWorkspace) &&
                      checkPermissionByPermissionName(
                        'viewGlobalVaribales'
                      ) &&
                      this.planDetails.diff
                      " to="/global-variables">
                      <el-dropdown-item>
                        <img src="@/assets/img/sideMenu/inactive_icons/Global Variable.svg" alt="menu"
                          style="height: 11px; width: 12px" />
                        Global Variables
                      </el-dropdown-item>
                    </router-link>
                    <!-- <el-dropdown-item>
											<router-link to="/company-profile"onmouseover>
												<i class="el-icon-office-building"></i> Company Profile
											</router-link>
                    </el-dropdown-item>-->
                    <!-- v-if="this.isWorkspaceIsBusiness(this.getActiveWorkspace) && checkPermissionByPermissionName('viewGroups') && this.planDetails.diff" -->
                    <router-link to="/company-entity-groups" v-if="this.isWorkspaceIsBusiness(this.getActiveWorkspace) &&
                      checkPermissionByPermissionName('viewMenuGroups') &&
                      this.planDetails.diff
                      ">
                      <el-dropdown-item>
                        <img src="@/assets/img/sideMenu/inactive_icons/Groups.svg" alt="menu"
                          style="height: 12px; width: 12px" />
                        Groups
                      </el-dropdown-item>
                    </router-link>
                    <router-link to="/formBuilders" v-if="this.isWorkspaceIsBusiness(this.getActiveWorkspace) &&
                      checkPermissionByPermissionName('viewFormBuilders') &&
                      this.planDetails.diff
                      ">
                      <el-dropdown-item>
                        <img src="@/assets/img/sideMenu/inactive_icons/Form.svg" alt="menu"
                          style="height: 12px; width: 13px" />
                        Form Builder
                      </el-dropdown-item>
                    </router-link>
                    <router-link to="/custom/dashboard-list" v-if="this.isWorkspaceIsBusiness(this.getActiveWorkspace) &&
                      checkPermissionByPermissionName('viewDashboards') &&
                      this.planDetails.diff
                      ">
                      <el-dropdown-item>
                        <img src="@/assets/img/sideMenu/inactive_icons/Custom Dashboard.svg" alt="menu"
                          style="height: 11px; width: 12px" />
                        Custom Dashboard
                      </el-dropdown-item>
                    </router-link>
                    <router-link to="/apps" v-if="this.isWorkspaceIsBusiness(this.getActiveWorkspace) &&
                      checkPermissionsCategory('marketPlace') &&
                      this.planDetails.diff &&
                      getCompanyDetails &&
                      !getCompanyDetails.is_admin_clone &&
                      !getCompanyDetails.is_cloned
                      ">
                      <el-dropdown-item>
                        <img src="@/assets/img/sideMenu/inactive_icons/Marketplace.svg" alt="menu"
                          style="height: 11px; width: 12px" />
                        Market Place
                      </el-dropdown-item>
                    </router-link>
                    <router-link to="/developer/portal">
                      <el-dropdown-item>
                        <i class="el-icon-cpu" style="height: 11px; width: 12px" alt="menu"></i>
                        Integrations Menu
                      </el-dropdown-item>
                    </router-link>
                    <a @click="logout()">
                      <el-dropdown-item>
                        <i class="el-icon-back"></i> Logout
                      </el-dropdown-item>
                    </a>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- <div class="vue-modal"> -->
    <dialog-component class="all-notifications-dialog" :width="getIsMobile ? '100%' : '60%'"
      :visible.sync="isViewAllNotificationsPopupShow" :title="'All Notifications'"
      @before-close="handleCloseAllNotificationsPopup" :show-close="true" :modalAppendToBody="false"
      ref="viewAllNotificationsPopup">
      <ViewAllNotifications v-if="isViewAllNotificationsPopupShow" />
    </dialog-component>
    <el-dialog class="all-notifications-dialog" :width="getIsMobile ? '100%' : '60%'"
      :visible.sync="isViewAllAppNotificationsPopupShow" :title="'All Notifications'"
      :show-close="true" :modalAppendToBody="false"
      ref="viewAllNotificationsPopup">
      <viewAllAppNotifications v-if="isViewAllAppNotificationsPopupShow" />
    </el-dialog>
    <!-- </div> -->

    <div class="vue-modal">
      <el-dialog class="all-chats-dialog" :width="getIsMobile ? '100%' : '60%'" :visible.sync="isViewAllChatsPopupShow"
        :title="'All Chats'" :before-close="handleCloseAllChatsPopup" :show-close="true" :modalAppendToBody="false"
        ref="viewAllChatsPopup">
        <ViewAllChats></ViewAllChats>
      </el-dialog>
    </div>

    <div>
      <!-- <vue-element-loading
        :active="show"
        spinner="bar-fade-scale"
        color="#FF6700"
      />-->
    </div>
  </section>
</template>

<script>
import { bus } from "../main";
import { mapGetters, mapActions } from "vuex";
import axios from "@/config/axios";
import ViewAllNotifications from "../components/notifications/ViewAllNotifications";
import ViewAllChats from "../components/notifications/ViewAllChats";
import config from "../config/app";
import PermissionsHelper from "@/mixins/permissionsHelper";
import Swal from "sweetalert2";
import appConfig from "@/config/app";
import chroma from 'chroma-js'; 

export default {
  name: "TopBarDefault",
  components: {
    ViewAllNotifications,
    ViewAllChats,
  },

  mixins: [PermissionsHelper],
  data() {
    return {
      loadingText: "Gathering your workspace resources, please wait...",
      brandingInfo: {},
      events: [
        "keyup",
        "keypress",
        "keydown",
        "click",
        "contextmenu",
        "dbclick",
        "mousedown",
        "mouseenter",
        "mouseleave",
        "mousemove",
        "mouseout",
        "mouseover",
        "mouseup",
        "error",
        "loadstart",
        "touchcancel",
        "touchend",
        "touchmove",
        "touchstart",
        "select",
        "unload",
        "scroll",
        "resize",
        "load",
        "beforeunload",
        "abort",
        "wheel",
      ],
      res: [],
      title: "",
      isOpen: false,
      isCollapse: "",
      routerValue: "",
      offCanvas: "",
      documentLogs: [],
      openProfile: false,
      isNotificationPopupShow: false,
      isAppNotificationPopupShow : false,
      selectedNotificationType : 'ALL',
      appNotificationTypes : [
        {
          name : "All",
          value : "ALL"
        },
        {
          name : "Entity",
          value : "ENTITY"
        },
        {
          name : "Form Builder",
          value : "FORM_BUILDER"
        },
        {
          name : "Email",
          value : "NOTIFICATION"
        },
        {
          name : "Others",
          value : "OTHERS"
        }
      ],
      isViewAllNotificationsPopupShow: false,
      isUnreadOnly : '',
      isViewAllAppNotificationsPopupShow : false,
      notification_search_string : '',
      appNotificationsLoading : false,
      allAppNotifications : null,
      isChatPopupShow: false,
      unreadNotificationsCount : null,
      totalNotificationsCount : null,
      allNotificationsCount : null,
      totalUnreadNotificationsCount : null,
      isViewAllChatsPopupShow: false,
      LoadMoreLoading : false,
      totalPageLogs : 0,
      isExist: false,
      userWorkspaceList: [],
      currentWorkspace: {},
      currentWorkspaceId: "",
      selectLoad: false,
      permissionsList: [],
      loading: false,
      isLoading: false,
      fullPage: true,
      canCancel: true,
      useSlot: false,
      loader: "spinner",
      color: "#F754A2",
      bgColor: "#b0aeae",
      height: 64,
      width: 64,
      timeout: 15000, //ms
      opacity: 0.7,
      activeRole: "",
      assignedRoles: [],
      baseURL: window.location.origin + "/signin",
      serverBaseURL: appConfig.AUTHFAST_BASE_URL,
      planDetails: {},
      loginPageColor: "#1B487E",
      isHorMenu: true,
      globalFilter: "",
      globalFilterLoading: false,
      globalFilterHasMoreData: false,
      allGlobalFilterEntityData: [],
      globalFilterLimit: 30,
      globalFilterPage: 1,
      globalFilterEntity: null,
      globalFilterSearch: "",
      isSearching: false,
      appSetting: {},
      logo: null,
      notificationsPageSize : 10,
      notificationsPage : 1,
      userTypes : []
    };
  },
  computed: {
    ...mapGetters("userStatus", [
      "getNotificationCountUnOpened",
      "getChatCountUnOpened",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsMobile",
      "getIsOpen"
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getAuthenticationStatus",
      "getWorkspaceList",
      "getUserType",
      "getSwitchWorkspaceError",
      "getPermissionList",
      "getPermissions",
      "getGlobalFiltervalue",
      "getAuthenticationDetails",
      "getUserTypeList",
      "getUserTypeEmailsErrors",
      "getUserTypeEmails",
    ]),
    ...mapGetters("dashboard", [
      "getNotifications",
      "getChats",
      "getNotificationsUnReadCnt",
      "getChatsUnReadCnt",
    ]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
      "getSingleCompanyLogo",
      "getContactLoginSlug",
    ]),
    ...mapGetters("paymentGateway", [
      "getPaymentsHistory",
      "getPlansFeatures"
    ]),
    ...mapGetters("menuManagementV2", [
      "getMenu"
    ]),
    ...mapGetters("entities", [
      "getEntityRecordsForTable"
    ]),
    ...mapGetters("appNotifications", [
      "getAllAppNotifications",
      "getAppNotificationErrors",
      "getMarkAllAsReadStatus",
    ]),
    isSSOUser() {
      return this.getAuthenticatedUser && this.getAuthenticatedUser.sso_user_id;
    },
    globalFilterExists() {
      if (this.getCompanyDetails?.global_filters) {
        let gFilter = this.getCompanyDetails.global_filters.find(
          (e) => e.include_in_topbar
        );
        return gFilter?.field_type ? gFilter : null;
      }
      return null;
    },
  },
  async mounted() {
    this.loading = true;
    this.appSetting = appConfig;
    let id =
      this.getActiveWorkspace &&
        this.getActiveWorkspace.company_id &&
        this.getActiveWorkspace.company_id._id
        ? this.getActiveWorkspace.company_id._id
        : this.getActiveWorkspace.company_id;
    await Promise.all([
      this.$store.dispatch("company/fetchCompany", id),
      this.$store.dispatch("company/fetchCompanyLogo", id),
    ]);
    try {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.contact_type) {
        await this.handleLogoutInfo();
      } else {
        this.loading = true;
        this.selectLoad = true;
        this.title = this.$route.meta.title || "Dashboard";
        var el = document
          .getElementsByClassName("all-notifications-dialog")[0]
          .getElementsByClassName("el-dialog__headerbtn")[0];
        var self = this;
        el.addEventListener("click", () => {
          self.isViewAllNotificationsPopupShow = false;
          document.querySelector("body").classList.remove("modal-open");
        });

        el = document
          .getElementsByClassName("all-chats-dialog")[0]
          .getElementsByClassName("el-dialog__headerbtn")[0];
        self = this;
        el.addEventListener("click", () => {
          self.isViewAllChatsPopupShow = false;
          document.querySelector("body").classList.remove("modal-open");
        });
        await Promise.all([this.getPermissionsList(),this.fetchUserData(), this.fetchNotifications(),this.fetchAppNotifications(),this.fetchChats(),this.getCompanyUserTypeList(),this.getStyleForHorMenu(),this.fetchUserStatus(),this.fetchRolesList(),this.setActiveRole(),this.getSubscriptionHistory()])
        if (this.isWorkspaceIsBusiness(this.getActiveWorkspace)) {
          if (!this.isIndividualWorkspace(this.getActiveWorkspace)) {
            await Promise.all([this.updateUserData(),this.getCompanyUserType(),this.getCompanyInfo()]);
          }
        }
        bus.$on("workspaceList", (data) => {
          this.userWorkspaceList = data;
        });
        if (this.getSingleCompanyLogo) {
          this.logo = `${appConfig.S3_BUCKET_URL}/${this.getSingleCompanyLogo}`;
        }

        this.loading = false;
      }
    } catch (error) {
      console.log("error", error);
    }
  },
  watch: {
    $route({ meta }) {
      this.title = meta.title || "Dashboard";
    },
    getPermissionList: async function () {
      if (this.getPermissionList) {
        const permissionsData = await Promise.all(
          this.getPermissionList.map(async (cat) => {
            const permissions = await Promise.all(
              cat.permissions.map((item) => item.slug)
            );
            return permissions;
          })
        );
        const permissionSet = permissionsData.flat();
        await this.$store.dispatch("auth/CompanyPermissionSet", permissionSet);
      }
    },
    getPaymentsHistory: async function () {
      if (
        this.getPaymentsHistory &&
        this.getPaymentsHistory.planFeatures &&
        this.getPaymentsHistory.planFeatures.length
      ) {
        this.planDetails =
          this.getPaymentsHistory &&
          this.getPaymentsHistory.planFeatures &&
          this.getPaymentsHistory.planFeatures[0];
        if (
          this.planDetails &&
          this.planDetails.plan == 1 &&
          this.planDetails.diff
        ) {
          this.getPermissionsListByCat();
        } else if (this.planDetails && this.planDetails.diff) {
          // Free Plan permissions
        } else {
          // expired
          if (
            this.$route.path == "/profile/subscription" ||
            this.$route.name == "subscription" ||
            this.$route.path == "/plans-list" ||
            this.$route.path == "/payments-status" ||
            this.$route.name == "profile" ||
            this.$route.name == "personal-information" ||
            this.$route.name == "change-password" ||
            this.$route.name == "signature"
          ) {
            //Subscription page
          } else {
            this.$router.push({
              path: `/expire?key=${Math.floor(Math.random() * (1000 - 1)) + 100
                }`,
            });
          }
        }
      }
      if(this.getPaymentsHistory &&this.getPaymentsHistory.planFeatures && this.getPaymentsHistory.planFeatures.length ) 
      { 
        let planDetails=this.getPaymentsHistory && this.getPaymentsHistory.planFeatures&&this.getPaymentsHistory.planFeatures[0];
          if(this.getUserType.name=="OWNER" &&planDetails && planDetails.plan&&planDetails.diff<=5 &&planDetails.diff>0){
            this.$notify.warning({
            title: 'Info',
            message: `Your plan is set to expire in  ${planDetails.diff} days. Don't miss out on our exclusive benefits`,
            showClose: false
          });
        }
        else if(this.getUserType.name=="OWNER" &&planDetails && planDetails.plan &&planDetails.diff==0){
          this.$notify.warning({
            title: 'Info',
            message: `Your plan has expired`,
            showClose: false
          });
        }
      }
    },
    getSingleCompanyLogo: async function () {
      if (this.getSingleCompanyLogo) {
        this.logo = `${appConfig.S3_BUCKET_URL}/${this.getSingleCompanyLogo}`;
      }
    },
    notification_search_string : {
      handler: function () {
        this.fetchAppNotifications();
      },
      deep: true,
    },
  },

  methods: {
    searchEntityData(query) {
      if (query) {
        this.isSearching = true;
        if (query.length > 2) {
          this.globalFilterSearch = query;
          this.page = 1;
          this.fetchGlobalFilterEntityData();
        }
      } else {
        this.isSearching = false;
        this.globalFilterSearch = "";
        this.page = 1;
        this.fetchGlobalFilterEntityData();
      }
    },
    getEntityDataLabel(selectedEntity, item, i) {
      let name =
        item &&
          item.entity_prime_data &&
          Object.keys(item.entity_prime_data) &&
          item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (
            item.entity_prime_data &&
            item.entity_prime_data[key] &&
            item.entity_prime_data[key + "/name"]
          ) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
                primaryField.template_id &&
                item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                    ? item.entityData[primaryField.template_id][key]
                    : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
    loadMoreEntityData(value) {
      if (value == "LOADMORE") {
        this.globalFilter = "";
        this.globalFilterPage++;
        this.loadMore = true;
        this.fetchGlobalFilterEntityData();
      } else {
        this.$store.commit("auth/setGlobalFiltervalue", value, {
          root: true,
        });
      }
    },
    getSearchPrimaryFields(index) {
      let result = [];
      if (
        this.globalFilterEntity?.primaryFields?.[index]?.template_id &&
        this.globalFilterEntity?.primaryFields?.[index]?.key
      ) {
        result = [
          this.globalFilterEntity?.primaryFields?.[index]?.template_id +
          "." +
          this.globalFilterEntity?.primaryFields?.[index]?.key,
        ];
      }
      return result;
    },
    async fetchGlobalFilterEntityData() {
      if (this.globalFilterExists?.entity_id) {
        this.globalFilterLoading = true;
        let params = {
          entity_id: this.globalFilterExists.entity_id,
          template_fields_data: [],
          filters: [],
          relationship: {},
          limit: this.globalFilterLimit,
          page: this.globalFilterPage,
        };
        if (this.globalFilterSearch) {
          params.search_string = this.globalFilterSearch;
          params.primary_search_fields = this.getSearchPrimaryFields(0);
        }
        await this.$store.dispatch(
          "entities/fetchEntityRecordsForTable",
          params
        );
        if (this.isSearching || !this.loadMore) {
          this.allGlobalFilterEntityData = [];
        }
        this.loadMore = false;
        if (this.getEntityRecordsForTable) {
          this.globalFilterHasMoreData = this.getEntityRecordsForTable.hasMore;
          this.allGlobalFilterEntityData = [
            ...(this.allGlobalFilterEntityData || []),
            ...this.getEntityRecordsForTable.data,
          ];
          this.globalFilterEntity =
            this.getEntityRecordsForTable.selectedEntity;
          if (this.getGlobalFiltervalue) {
            this.globalFilter = this.getGlobalFiltervalue;
          }
          if (this.globalFilter) {
            let existed = this.allGlobalFilterEntityData.find(e => e._id == this.globalFilter);
            if (!existed) {
              this.globalFilter = "";
              this.$store.commit("auth/setGlobalFiltervalue", "", {
                root: true,
              });
            }
          }
        }
        this.globalFilterLoading = false;
      }
    },
    getStyleForHorMenu() {
      if (
        this.getCompanyDetails &&
        this.getCompanyDetails.menuType &&
        this.getCompanyDetails.menuType == "Horizontal"
      ) {
        // return 'position: relative !important;';
        this.isHorMenu = false;
        document.getElementById("MainLayout").style.marginTop = "60px";
        document.getElementById("MainLayout").style.paddingTop = "80px";
        document.getElementById("MainLayout").style.paddingLeft = "30px";
        document.getElementById("MainLayout").style.paddingRight = "10px";
        return (document.getElementById("topBarHeader").style.position =
          "fixed");
      } else {
        // return '';
        document.getElementById("MainLayout").style.marginTop = "67px";
        document.getElementById("MainLayout").style.paddingTop = "30px";
        document.getElementById("MainLayout").style.paddingLeft = "100px";
        document.getElementById("MainLayout").style.paddingRight = "30px";

        return (document.getElementById("topBarHeader").style.position =
          "fixed");
      }
    },
    async getSubscriptionHistory() {
      try {
        let email = "info@esigns.io";
        await this.$store.dispatch("paymentGateway/fetchSubscriptionInfo", {
          email,
        });
      } catch (error) {
        console.log(error);
      }
    },

    onidle() {
      // alert('You have been logged out');
      let timerInterval;
      Swal.fire({
        title: "Session Timed Out",
        html: `Your session has been automatically logged out due to inactivity`,
        timer: 4000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
          this.logout();
        },
      });
    },
    onremind(time) {
      let timerInterval;
      Swal.fire({
        title: "",
        html: `Your session will automatically log out after ${time} seconds due to inactivity`,
        timer: 4000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
          // this.logout();
        },
      });
    },

    async fetchRolesList() {
      if (this.getAuthenticatedUser && this.getAuthenticatedUser.roles) {
        this.assignedRoles = this.getAuthenticatedUser.roles;
      } else {
        this.assignedRoles = [];
      }
    },
    async setActiveRole() {
      this.activeRole =
        this.getAuthenticatedUser &&
          this.getAuthenticatedUser.activeRole &&
          this.getAuthenticatedUser.activeRole.userType_id
          ? this.getAuthenticatedUser.activeRole.userType_id
          : "";
    },
    async handleLogoutInfo() {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Due to inactivity for a prolonged period, your session has been automatically logged out for security purposes. Please feel free to log in again when you're ready!",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          let timerInterval;
          Swal.fire({
            title: "",
            html: "Please wait ..... ",
            timer: 2000,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
              clearInterval(timerInterval);
              this.logout();
            },
          });
        }
      });
    },
    getLabel() {
      return (userType) => {
        if (userType && userType.role) {
          return userType.role.title + " - " + userType.name;
        } else {
          return userType.name;
        }
      };
    },

    ...mapActions({
      fetchUserStatus: "userStatus/fetchUserStatus",
      updateUserStatus: "userStatus/updateUserStatus",
      fetchNotifications: "dashboard/fetchNotifications",
      fetchChats: "dashboard/fetchChats",
      fetchPermissionList: "auth/getPermissions",
      fetchPermissions: "auth/updatePermissions",
      fetchAllAppNotifications : "appNotifications/fetchAllAppNotifications"
    }),
    handleCloseAllNotificationsPopup() {
      this.isViewAllNotificationsPopupShow = false;
      //no lines
    },
    async getPermissionsList() {
      this.loading = true;
      try {
        await this.$store.dispatch("auth/getPermissions");
        if (this.getPermissionList) {
          this.permissionsList = this.getPermissionList;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getPermissionsListByCat() {
      try {
        let data = {
          categories: this.getPaymentsHistory.featuresList,
        };
        await this.$store.dispatch("auth/getPermissionsByCat", data);
        if (this.getPermissionList) {
          this.permissionsList = this.getPermissionList;
        }
        this.loagin = false;
      } catch (err) {
        console.log(err);
      }
    },

    handleCloseAllChatsPopup() { },
    async goToChats(document_id, isChat = false) {
      if (isChat) {
        this.markAsReadChats({
          document_id,
        });
      }
      if (document) {
        if (document.document_status == "DRAFT") {
          this.$router.push({
            name: "employee-documents-custom-document-edit",
            params: {
              employee_document_id: document_id,
            },
          });
        } else {
          this.$router.push({
            name: "employee-documents-custom-document",
            params: {
              employee_document_id: document_id,
            },
            query: {
              tab: "chat",
            },
          });
        }
      }
    },
    async goToDocView(document) {
      if (
        document &&
        document.employee_document_id &&
        document.employee_document_id._id
      ) {
        this.markAsReadEachActivities(document._id);
        if (document) {
          if (document.document_status == "DRAFT") {
            this.$router.push({
              name: "employee-documents-custom-document-edit",
              params: {
                employee_document_id: document.employee_document_id._id,
              },
            });
          } else {
            this.$router.push({
              name: "employee-documents-custom-document",
              params: {
                employee_document_id: document.employee_document_id._id,
              },
              query: {
                tab: "logs",
              },
            });
          }
        }
      } else {
        this.$router.push({
          path: "/templates",
        });
      }
    },
    viewAllNotifications() {
      this.isViewAllNotificationsPopupShow = true;
      this.isNotificationPopupShow = false;
      document.body.className += " modal-open";
    },
    viewAllAppNotifications() {
      this.isViewAllAppNotificationsPopupShow = true;
      this.isAppNotificationPopupShow = false;
      document.body.className += " modal-open";
    },
    viewAllChats() {
      this.isViewAllChatsPopupShow = true;
      this.isChatPopupShow = false;
      document.body.className += " modal-open";
    },
    async toggleNotificationPopup() {
      if (this.getNotifications && !this.getNotifications.length) {
        this.$message.info("You have no notifications yet to display.");
      }
      this.isNotificationPopupShow = !this.isNotificationPopupShow;
      if (this.isNotificationPopupShow) {
        this.updateUserStatus({
          notification_last_open_date: "1",
        });
        this.fetchUserStatus();
      }
    },
    async toggleAppNotificationPopup() {
      this.isAppNotificationPopupShow = !this.isAppNotificationPopupShow;
      if (this.isAppNotificationPopupShow && !this.allAppNotifications?.length) {
        this.fetchAppNotifications();
      }
    },
    async fetchAppNotifications() {
      this.appNotificationsLoading = true;
      let params = {
        type : this.selectedNotificationType
      }
      if(this.notification_search_string) {
        params["search_string"] = this.notification_search_string;
      }
      if(this.isUnreadOnly == 'UNREAD') {
        params['is_read'] = false;
      }
      await this.fetchAllAppNotifications(params);
      if(this.getAllAppNotifications) {
       this.allAppNotifications = this.getAllAppNotifications.data;
       this.unreadNotificationsCount = this.getAllAppNotifications.unread_count;
       this.totalNotificationsCount = this.getAllAppNotifications.total_notifications_count;
       this.allNotificationsCount = this.getAllAppNotifications.total_count;
       this.totalUnreadNotificationsCount = this.getAllAppNotifications.total_unread_count;
      }
      this.totalPageLogs = 20;
      this.appNotificationsLoading = false;
    },
    async loadMoreLogs() {
      this.LoadMoreLoading = true;
      let params = {
        type : this.selectedNotificationType,
        skip : this.totalPageLogs,
        limit : 20,
        is_load_more : true
      }
      if(this.notification_search_string) {
        params["search_string"] = this.notification_search_string;
      }
      if(this.isUnreadOnly == 'UNREAD') {
        params['is_read'] = false;
      }
      await this.fetchAllAppNotifications(params);
      if(this.getAllAppNotifications) {
        this.totalPageLogs = this.totalPageLogs + 20;
        this.allAppNotifications = [
          ...this.allAppNotifications ,
          ...this.getAllAppNotifications.data
      ] 
      }
      this.LoadMoreLoading = false;
    },
    async toggleChatPopup() {
      if (this.getChats && !this.getChats.length) {
        this.$message.info("You have no chats yet to display.");
      }
      this.isChatPopupShow = !this.isChatPopupShow;
      if (this.isChatPopupShow) {
        this.updateUserStatus({
          chat_last_open_date: "1",
        });
        this.fetchUserStatus();
      }
    },
    unmark(doc) {
      if (
        doc.sender_email &&
        doc.sender_email.email == this.getAuthenticatedUser.email &&
        doc.sender_email.is_read &&
        doc.sender_email.is_read == true
      ) {
        return doc.sender_email.is_read;
      } else {
        let user = doc.rec_email.find(
          (x) =>
            x.email && x.email == this.getAuthenticatedUser.email && x.is_read
        );
        return user;
      }
    },
    async markAsReadEachActivities(query = {}) {
      try {
        await axios.post("all-documents/mark-as-read-each-activities", {
          query,
        });
        if (this.getNotificationsUnReadCnt > 0) {
          await this.fetchNotifications();
        }
      } catch (err) {
        console.log(">>> markAsReadActivities(), err", err);
      }
    },
    async markAsReadActivities() {
      try {
        await axios.post("all-documents/mark-as-read-activities");
        await this.fetchNotifications();
        if (this.getNotificationsUnReadCnt > 0) {
          await this.fetchNotifications();
        }
      } catch (err) {
        console.log(">>> markAsReadActivities(), err", err);
      }
    },
    async markAsReadChats(query = {}) {
      try {
        await axios.post("all-documents/mark-as-read-notes", {
          query,
        });

        if (this.getChatsUnReadCnt > 0) {
          await this.fetchChats();
        }
      } catch (err) {
        console.log(">>> markAsReadChats(), err", err);
      }
    },
    async markAppNotificationsAsReadChats() {
      if(this.unreadNotificationsCount) {
        await this.$store.dispatch(
        "appNotifications/markAllAsRead",
        { type : this.selectedNotificationType }
      );
      if(this.getMarkAllAsReadStatus) {
        this.fetchAppNotifications();
      }
      }
      else {
        this.$message.warning("You don't have any unread Notifications")
      }
    },
    hideNotificationPopup() {
      this.isNotificationPopupShow = false;
    },
    hideAppNotificationPopup() {
      this.isAppNotificationPopupShow = false;
    },
    async handleNotificationClick(item) {
      if(!item.is_read) {
      this.totalUnreadNotificationsCount = this.totalUnreadNotificationsCount -1;
      }
      let routerPath= "";
      let params = {
        id : item._id,
        data : {
          is_read : true
        }
      }
      this.$store.dispatch("appNotifications/updateDataById",params);
      this.isAppNotificationPopupShow = false;
      let keyValue = Math.floor(Math.random() * 100000);
      switch(item.type) {
        case 'ENTITY' :
          switch(item.when) {
            case 'ADDED' :
            case 'UPDATED' :
              routerPath = `/entity/view/${item.entity_id._id}?key=${keyValue}&dataId=${item.entity_data_id}&filter=&layout=PROFILE&page=1&pageSize=10`;
              break;
            case 'BULK_UPDATE' :
              routerPath = `/entity/${item.entity_id._id}?key=${keyValue}`;
              break;
            default :
              routerPath = "";
          }
          break;
        case 'FORM_BUILDER' :
          switch(item.when) {
            case "FILLED" :
            case "APPROVED" :
            case "REJECTED" :
              routerPath = `/fbd/${item.form_builder_id?.code}?key=${keyValue}`;
              break;
            default : 
              routerPath = "";
          }
          break;
        case 'NOTIFICATION' :
            if(item.mail_sending_type == 'ENTITY') {
              routerPath = `/entity/view/${item.entity_id._id}?key=${keyValue}&dataId=${item.entity_data_id}&filter=&layout=PROFILE&page=1&pageSize=10`;
            } else if (item.mail_sending_type == 'FORM_BUILDER') {
              routerPath = `/fbd/${item.form_builder_id?.code}?key=${keyValue}`;
            }
            break;
        default :
          routerPath = "";
          break;
      }
      item.is_read = true;
      this.$router.push({
        path: routerPath,
      });
          // window.location.reload();
      //updateLogToRead and fetch again
      this.fetchAppNotifications();
    },
    getNotificationTypeAvatar(type) {
      let name = "";
      switch(type) {
        case 'ENTITY' :
          name = "EN";
          break;
        case 'FORM_BUILDER' :
          name = "FB";
          break;
        case 'NOTIFICATION' :
          name = "NF";
          break;
        case 'OTHERS' :
          name = "OT";
          break;
        default :
          name = "";
          break;
      }
      return name;
    },
    getAppNotificationDescription(item) {
      let description= "";
      switch(item.type) {
        case 'ENTITY' :
          switch(item.when) {
            case 'ADDED' :
              description = `A new entity data is <b>added</b> in <b>${item.entity_id?.name}</b>.`;
              break;
            case 'UPDATED' :
              description = `An entity data is <b>updated</b> in <b>${item.entity_id?.name}</b>.`;
              break;
            case 'DELETED' :
              description = `A entity data is <b>deleted</b> in <b>${item.entity_id?.name}</b>.`
              break;
            case 'BULK_UPDATE' :
              description = `Bulk update was performed in <b>${item.entity_id?.name}</b>.`
              break;
            default :
              description = "Action in Entity";
              break;
          }
          break;
        case 'FORM_BUILDER' :
          switch(item.when) {
            case 'FILLED' :
              description = `A new form data is <b>filled</b> in <b>${item.form_builder_id?.name}</b>`;
              break;
            case 'APPROVED' :
              description = `A form data is <b>approved</b> by <span style="color: #409eff">${item.user_details.name}</span> in <b>${item.form_builder_id?.name}</b>`;
              break;
            case 'REJECTED' :
              description = `A form is <b>rejected</b> by <span style="color: #409eff">${item.user_details.name}</span> in <b>${item.form_builder_id?.name}</b>`
              break;
            default :
              description = "Action in Form Builder";
              break;
          }
          break;
        case 'NOTIFICATION' :
          if(item.entity_data_id && item.entity_data_id) {
            description = `Email successfully sent to an entity data in <b>${item.entity_id?.name}</b>.`
          } else if (item.form_builder_data_id) {
            description = `Email successfully sent to a form data in <b>${item.form_builder_id?.name}</b>.`
          }
          break;
        default :
          description = "";
          break;
      }
      return description;
    },
    hideChatPopup() {
      this.isChatPopupShow = false;
    },
    logout() {
      let timerInterval;
      Swal.fire({
        title: "",
        html: "E-Signs signing off....",
        timer: 3000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading();
          localStorage.removeItem(config.APP_NAME + "_" + config.APP_VERSION);
          localStorage.clear();
          if (this.isSSOUser) {
            window.location.href =
              this.serverBaseURL +
              "/logout/client?redirect_url=" +
              this.baseURL;
            return;
          } else {
            this.resetStore();
          }
        },
        willClose: () => {
          clearInterval(timerInterval);
          this.resetStore();
          if (this.isSSOUser) {
            window.location.href =
              this.serverBaseURL +
              "/logout/client?redirect_url=" +
              this.baseURL;
            return;
          }
          this.$router.push({
            path: "/signin",
          });
        },
      });
    },
    sendMessageToParent() {
      this.$store.commit("navigationOpen/setIsOpen", !this.getIsOpen, {
        root: true,
      });
      this.$emit("open:sidebar", !this.isOpen);
    },
    resetStore() {
      this.$store.commit("auth/resetAuthData", null, {
        root: true,
      });
      this.$store.commit("sso/resetAuthData", null, {
        root: true,
      });
      this.$store.commit("entityGroups/resetData", null, {
        root: true,
      });
      this.$store.commit("menuManagementV2/resetData", null, {
        root: true,
      });
      this.$store.commit("menuManagement/resetData", null, {
        root: true,
      });
      this.$store.commit("globalVariables/resetData", null, {
        root: true,
      });
      this.$store.commit("repeatableTemplates/resetData", null, {
        root: true,
      });
      this.$store.commit("templatesData/resetData", null, {
        root: true,
      });
      this.$store.commit("entities/resetData", null, {
        root: true,
      });
      this.$store.commit("companyTemplates/resetData", null, {
        root: true,
      });
      this.$store.commit("templateWorkflow/resetData", null, {
        root: true,
      });
      this.$store.commit("company/resetData", null, {
        root: true,
      });
      this.$store.commit("userStatus/resetData", null, {
        root: true,
      });
      this.$store.commit("subscription/resetData", null, {
        root: true,
      });
      this.$store.commit("templates/resetData", null, {
        root: true,
      });
      this.$store.commit("settings/resetData", null, {
        root: true,
      });
      this.$store.commit("dashboard/resetData", null, {
        root: true,
      });
      this.$store.commit("contactTypes/resetData", null, {
        root: true,
      });
      this.$store.commit("documentNotes/resetData", null, {
        root: true,
      });
      this.$store.commit("configurableDocuments/resetData", null, {
        root: true,
      });
      this.$store.commit("contacts/resetData", null, {
        root: true,
      });
      this.$store.commit("s3FileUpload/resetData", null, {
        root: true,
      });
      this.$store.commit("documents/resetData", null, {
        root: true,
      });
      this.$store.commit("errors/resetData", null, {
        root: true,
      });
      this.$store.commit("fileUpload/resetData", null, {
        root: true,
      });
      this.$store.commit("errors/reset", null, {
        root: true,
      });

      this.$store.commit("employeeData/reset", null, {
        root: true,
      });
      this.$store.commit("employeeDocuments/reset", null, {
        root: true,
      });
    },
    async fetchUserData() {
      if (this.getActiveWorkspace) {
        this.currentWorkspace = this.getActiveWorkspace;
        this.currentWorkspaceId = this.currentWorkspace.company_id;
        this.userWorkspaceList = this.getWorkspaceList;

        let info = await this.updateIndividualWorkspace(
          this.getActiveWorkspace
        );
        this.currentWorkspace = info.currentWorkspace;
        this.userWorkspaceList = info.userWorkspaceList;

        this.selectLoad = false;
      } else {
        await this.handleLogoutInfo();
      }
    },

    async getCompanyInfo() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "company/fetchCompany",
          this.getActiveWorkspace.company_id
        );
        if (this.getCompanyDetails) {
          this.brandingInfo = {
            ...this.getCompanyDetails,
          };
         await Promise.all([this.applyTheme(),
          this.fetchGlobalFilterEntityData()])
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    async updateUserData() {
      this.loading = true;
      await this.$store.dispatch(
        "auth/fetchUsersWithEmail",
        this.getAuthenticatedUser.email
      );
      if (this.getWorkspaceList) {
        let info = await this.updateIndividualWorkspace(
          this.getActiveWorkspace
        );
        this.currentWorkspace = info.currentWorkspace;
        this.userWorkspaceList = info.userWorkspaceList;
      } else {
        this.userWorkspaceList = [];
      }
    },
    async getCompanyUserType() {
      try {
        await this.$store.dispatch("auth/getUserTypeById", this.activeRole);
      } catch (err) {
        console.log(err);
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    async changeRole() {
      this.loading = true;
      let timerInterval;
      Swal.fire({
        title: "",
        html: "Validation process is going on.It will take few seconds.Please wait....",
        timer: 15000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
      await this.$store.dispatch(
        "auth/fetchUsersWithEmail",
        this.getAuthenticatedUser.email
      );

      if (this.getWorkspaceList) {
        let presentWorkSpace = this.getWorkspaceList.filter((u) => {
          if (
            u.company_id == this.currentWorkspace.company_id ||
            u.company_id._id == this.currentWorkspace.company_id
          ) {
            return u;
          }
        });
        let selectedWorkSpace = this.getWorkspaceList.filter((u) => {
          if (
            u.company_id == this.currentWorkspaceId ||
            u.company_id._id == this.currentWorkspaceId
          ) {
            return u;
          }
        });
        if (
          presentWorkSpace &&
          presentWorkSpace.length &&
          presentWorkSpace[0] &&
          presentWorkSpace[0].status === "ACTIVE"
        ) {
          if (selectedWorkSpace && selectedWorkSpace.length) {
            if (
              selectedWorkSpace[0] &&
              selectedWorkSpace[0].status === "ACTIVE"
            ) {
              Swal.close();
              await this.changeWorkspaceRole();
            } else if (
              selectedWorkSpace[0] &&
              selectedWorkSpace[0].status === "HOLD"
            ) {
              Swal.close();
              await this.activateAccount();
            } else if (
              selectedWorkSpace[0] &&
              selectedWorkSpace[0].status === "INACTIVE"
            ) {
              await this.revertOldWorkspace(
                "This workspace is in inactive state. Please contact administrator"
              );
            } else {
              await this.revertOldWorkspace(
                "This workspace is not found in your records . Please verify once "
              );
            }
          } else {
            await this.revertOldWorkspace(
              "This workspace is not found in your records . Please verify once "
            );
          }
        } else if (
          presentWorkSpace &&
          presentWorkSpace.length &&
          presentWorkSpace[0] &&
          presentWorkSpace[0].status === "HOLD"
        ) {
          await this.handleLogoutInfo(
            "This workspace is in Hold state. Please contact to  administrator.Please re-login."
          );
        } else if (
          presentWorkSpace &&
          presentWorkSpace.length &&
          presentWorkSpace[0] &&
          presentWorkSpace[0].status === "INACTIVE"
        ) {
          await this.handleLogoutInfo(
            "This workspace is in inactive state. Please contact to administrator.Please re-login."
          );
        }
      }
    },

    async ChangeAccount() {
      this.loadingText = "Switching your workspace..."
      this.loading = true;
      let timerInterval;
      Swal.fire({
        title: "",
        html: "Validation process is going on.It will take few seconds.Please wait....",
        timer: 15000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
      await this.$store.dispatch(
        "auth/fetchUsersWithEmail",
        this.getAuthenticatedUser.email
      );

      if (this.getWorkspaceList) {
        let presentWorkSpace = this.getWorkspaceList.filter((u) => {
          if (
            u.company_id == this.currentWorkspace.company_id ||
            u.company_id._id == this.currentWorkspace.company_id
          ) {
            return u;
          }
        });
        let selectedWorkSpace = this.getWorkspaceList.filter((u) => {
          if (
            u.company_id == this.currentWorkspaceId ||
            u.company_id._id == this.currentWorkspaceId
          ) {
            return u;
          }
        });
        if (
          presentWorkSpace &&
          presentWorkSpace.length &&
          presentWorkSpace[0] &&
          presentWorkSpace[0].status === "ACTIVE"
        ) {
          if (selectedWorkSpace && selectedWorkSpace.length) {
            if (
              selectedWorkSpace[0] &&
              selectedWorkSpace[0].status === "ACTIVE"
            ) {
              Swal.close();
              await this.changeWorkspace();
            } else if (
              selectedWorkSpace[0] &&
              selectedWorkSpace[0].status === "HOLD"
            ) {
              Swal.close();
              await this.activateAccount();
            } else if (
              selectedWorkSpace[0] &&
              selectedWorkSpace[0].status === "INACTIVE"
            ) {
              await this.revertOldWorkspace(
                "This workspace is in inactive state. Please contact administrator"
              );
            } else {
              await this.revertOldWorkspace(
                "This workspace is not found in your records . Please verify once "
              );
            }
          } else {
            await this.revertOldWorkspace(
              "This workspace is not found in your records . Please verify once "
            );
          }
        } else if (
          presentWorkSpace &&
          presentWorkSpace.length &&
          presentWorkSpace[0] &&
          presentWorkSpace[0].status === "HOLD"
        ) {
          await this.handleLogoutInfo(
            "This workspace is in Hold state. Please contact to  administrator.Please re-login."
          );
        } else if (
          presentWorkSpace &&
          presentWorkSpace.length &&
          presentWorkSpace[0] &&
          presentWorkSpace[0].status === "INACTIVE"
        ) {
          await this.handleLogoutInfo(
            "This workspace is in inactive state. Please contact to administrator.Please re-login."
          );
        }
      }
    },
    async changeWorkspaceRole() {
      this.loadingText = "Switching role..."
      this.loading = false;
      Swal.close();
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure to switch the Role?",
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.loadingAlert(10000);
          await this.$store.dispatch("auth/switchWorkspace", {
            currentWorkspaceId: this.currentWorkspaceId,
            activeRole: this.activeRole,
          });
          if (this.getAuthenticationStatus) {
            this.$store.commit("errors/reset", null, {
              root: true,
            });
            this.currentWorkspace = this.getAuthenticatedUser.current_workspace;
            await this.fetchUserDataAfterSwitch();
            if (this.isWorkspaceIsBusiness(this.getActiveWorkspace)) {
              await this.getCompanyUserType();
            }
            await this.$store.dispatch("auth/fetchProfile");
            await this.$router.push({
              path: `/dashboard?route=${Math.floor(Math.random() * (1000 - 1)) + 100
                }`,
            });
            window.location.reload();
            Swal.close();
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.currentWorkspaceId = this.currentWorkspace.company_id;
          this.setActiveRole();
        }
      });
    },
    async changeWorkspace() {
      this.loadingText = "Switching workspace..."
      this.loading = false;
      Swal.close();
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure to switch the workspace?",
        icon: "warning",
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.loadingAlert(10000);
          await this.$store.dispatch("auth/switchWorkspace", {
            currentWorkspaceId: this.currentWorkspaceId,
          });
          if (this.getAuthenticationStatus) {
            this.$store.commit("errors/reset", null, {
              root: true,
            });
            this.currentWorkspace = this.getAuthenticatedUser.current_workspace;
            await this.fetchUserDataAfterSwitch();
            if (this.isWorkspaceIsBusiness(this.getActiveWorkspace)) {
              await this.getCompanyUserType();
              await this.$store.dispatch("settings/fetchUserDashboardStatics");
            }
            await this.$store.dispatch("auth/fetchProfile");
            await this.$store.dispatch("settings/fetchUserDashboardStatics");
            await this.$router.push({
              path: `/dashboard?route=${Math.floor(Math.random() * (1000 - 1)) + 100
                }`,
            });
            window.location.reload();
            Swal.close();
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.currentWorkspaceId = this.currentWorkspace.company_id;
          this.setActiveRole();
        }
      });
    },
    async activateAccount() {
      this.loading=false;
      Swal.close();
      Swal.fire({
        title: "Are you sure?",
        text: "This workspace is in hold state. Do you want to activate it?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.loadingAlert(10000);
          try {
            await this.$store.dispatch(
              "auth/verifyAndSwitch",
              this.currentWorkspaceId
            );
            if (this.getAuthenticationStatus) {
              Swal.close();
              await this.changeWorkspace();
            } else {
              await this.revertOldWorkspace("Failed to active the account");
            }
          } catch (HoldError) {
            console.log(HoldError);
            Swal.close();
            await this.revertOldWorkspace("");
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.currentWorkspaceId = this.currentWorkspace.company_id;
        }
      });
    },

    async fetchUserDataAfterSwitch() {
      this.loading = true;
      await this.$store.dispatch(
        "auth/fetchUsersWithEmail",
        this.getAuthenticatedUser.email
      );
      if (this.getWorkspaceList) {
        let info = await this.updateIndividualWorkspace(
          this.getActiveWorkspace
        );
        this.currentWorkspace = info.currentWorkspace;
        this.userWorkspaceList = info.userWorkspaceList;
      } else {
        this.userWorkspaceList = [];
      }
    },

    async revertOldWorkspace(msg = "") {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: msg ? msg : "Something went wrong!",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.currentWorkspaceId = this.currentWorkspace.company_id;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.currentWorkspaceId = this.currentWorkspace.company_id;
        }
      });
    },
    async loadingAlert() {
      this.loadingText = "Switching your workspace..."
      this.loading = true;
      Swal.close();
      // let timerInterval;
      // Swal.fire({
      //   title: "",
      //   html: "Please wait....",
      //   timer: timer,
      //   allowOutsideClick: false,
      //   allowEscapeKey: false,
      //   allowEnterKey: false,
      //   didOpen: () => {
      //     Swal.showLoading();
      //   },
      //   willClose: () => {
      //     clearInterval(timerInterval);
      //   },
      // });
    },
    async applyTheme() {
      // Apply theme using company info
      // Ensure that this.companyInfo is set before applying theme
      document.documentElement.style.setProperty('--primary-color', this.getCompanyDetails.dialog_header_color);
      document.documentElement.style.setProperty('--primary-contrast-color', this.getCompanyDetails.dialog_header_text_color);
      document.documentElement.style.setProperty('--sidemenu-background-color', this.getCompanyDetails.sidemenu_background);
      document.documentElement.style.setProperty('--sidemenu-text-color', this.getCompanyDetails.sidemenu_text);
      const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim();
      const lighterPrimaryColor = chroma(primaryColor).brighten(0.5).hex();
      const darkerPrimaryColor = chroma(primaryColor).darken(0.5).hex();
      document.documentElement.style.setProperty('--darker-primary-color', darkerPrimaryColor);
      document.documentElement.style.setProperty('--lighter-primary-color', lighterPrimaryColor);
      // document.documentElement.style.setProperty('--Hover-color-inputfield', this.companyInfo.menubackground);
      // document.documentElement.style.setProperty('--text-secondary-color', 'white');
    
      const secondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-color').trim();

      const darkerSecondaryColor = chroma(secondaryColor).brighten(0.3).hex();

      document.documentElement.style.setProperty('--darker-secondary-color', darkerSecondaryColor);

      /*** Based On company Profile setting, Top bar color will apply - Block Start */          
      if (this.getCompanyDetails?.menubackground) {
        this.loginPageColor = this.getCompanyDetails.menubackground;
      } else {
        this.loginPageColor = "#1B487E";
      }
      this.loading = false;
      /*** Based On company Profile setting, Top bar color will apply - Block end */ 
    },
    async getCompanyUserTypeList() {
      if (this.getActiveWorkspace && this.getActiveWorkspace?.company_id) {
        try {
          await this.$store.dispatch("auth/getUserTypeList", {
            companyId: this.getActiveWorkspace.company_id,
            isMenu: true,
          });
          if (this.getUserTypeList) {
            this.userTypes = this.getUserTypeList;
            this.getUserTypeEmailsList();
          }
        } catch (err) {
          this.$notify.error({
            title: "Error",
            message: err,
          });
        }
      }
    },
    async getUserTypeEmailsList() {
      try {
        if (!this.userTypes.length) {
          return ;
        }
        let AllUserTypeIds = this.userTypes.map((e) => e._id);
        let params = {
          userTypes: AllUserTypeIds,
        };
        await this.$store.dispatch("auth/fetchUserTypeEmails", params);
        if (this.getUserTypeEmails) {
          this.userTypesEmails = this.getUserTypeEmails.data;
        }
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: this.getUserTypeEmailsErrors,
        });
      }
    },
  },
};
</script>

<style lang="scss">
body.modal-open {
  height: 100vh;
  //overflow-y: hidden;
}

.top-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  // background: #1b487e;
  // color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform : scale(1.0);
  padding: 5px 10px;

  .top-navbar-left-menu {
    ul {
      display: flex;
      align-items: center;

      li {
        &:not(:last-child) {
          margin-right: 25px;
        }

        .company-logo {
          padding-top: 15px;
          padding-bottom: 10px;

          img {
            width: 100px;
          }
        }
      }
    }
  }

  .top-navbar-right-menu {
    .logout-dropdown {
      float: right;
      position: relative;
      display: flex;
      align-items: center;

      .alert-icon {
        position: relative;
        margin-right: 35px;

        .alert-count {
          position: absolute;
          top: -12px;
          right: -6px;

          span {
            background: #f754a2;
            font-size: 0.8em;
            border: 1px solid #f754a2;
            color: #ffffff;
            padding: 2px;
            width: 9px;
            height: 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
          }
        }

        img {
          width: 22px;
          margin-top: 5px;
        }
      }

      .top-search {
        .el-input__inner {
          border-radius: 3px;
          background: rgba(236, 245, 255, 0.1);
          font-size: 0.9rem;
          line-height: 2;
          width: calc(100% - 22px);
          border-color: rgba(236, 245, 255, 0.2);
          height: 38px;
          color: #ffffff;

          &:focus {
            border-color: #1b487e;
          }
        }

        .el-input__icon {
          line-height: 27px !important;
        }
      }

      .el-dropdown {
        font-size: 15px;
        padding-right: 0;
        display: flex;
        align-items: center;

        button {
          padding: 0;
          margin: 0;
          background: transparent;
          border: none;
        }

        &:hover {
          cursor: pointer;
        }

        .user-image {
          img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            margin-right: 10px;
            margin-bottom: -20px;
          }
        }

        .icon-block {
          display: flex;
          align-items: center;
        }

        .icon-text {
          font-size: 1.1em;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: rgba($color: #ffffff, $alpha: 0.85);

          &:hover {
            color: rgba($color: #ffffff, $alpha: 1);
          }

          .dropdown-arrow {
            img {
              width: 10px;
            }
          }
        }

        .user-profile-image {
          img {
            width: 25px;
          }
        }
      }
    }
  }

  .toggle-menu-animation {
    .hidden {
      visibility: hidden;
    }

    /** burger **/

    button {
      cursor: pointer;
    }

    /* remove blue outline */
    button:focus {
      outline: 0;
    }

    .burger-button {
      position: relative;
      height: 30px;
      width: 40px;
      display: block;
      z-index: 60;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      pointer-events: all;
      transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .burger-bar {
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      right: 6px;
      left: 6px;
      height: 2px;
      width: auto;
      margin-top: -1px;
      transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      opacity: 1;
    }

    .burger-bar--1 {
      -webkit-transform: translateY(-6px);
      transform: translateY(-8px);
    }

    .burger-bar--3 {
      transform: translateY(8px);
    }

    #burger.active .burger-button {
      transform: rotate(-180deg);
    }

    #burger.active .burger-bar--1 {
      transform: rotate(45deg);
    }

    #burger.active .burger-bar--2 {
      opacity: 0;
    }

    #burger.active .burger-bar--3 {
      transform: rotate(-45deg);
    }
  }

  .user-role {
    color: rgba($color: #ffffff, $alpha: 0.95);
    font-size: 0.8em;
    font-weight: 300;
  }
}

.card:hover .img-top {
  display: inline;
}

.notification-wrapper {
  position: relative;
  width: 50px;
  height: 50px;

  @media (min-width: 800px) {
    margin-right: -7px;
  }

  cursor: pointer;

  @media (max-width: 400px) {
    width: 40px;
  }

  .user-image {
    img {
      margin-top: 18px;
      width: 20px;

      &.chat {
        margin-top: 21px;
      }
    }
  }

  .number-on-bell {
    height: 17px;
    min-width: 13px;
    background-color: #f754a2;
    border-radius: 20px;
    color: white;
    text-align: center;
    position: absolute;
    top: 7px;

    &.chat {
      top: 6px;
    }

    left: 15px;
    padding: 0px 2px 0px 2px;
    font-size: 12px;
    font-weight: 400;
  }
}

.notification-popup {
  margin-right: -40px;
  border: 1px solid #e3eafd;
  border-radius: 5px;

  @media (max-width: 499.98px) {
    // width: 100vw;

    // top: 55px;
    .arrow {
      display: none;
    }

    // .list-group-flush {
    // 	padding: 0px 5px 10px 5px !important;
    // }
    left: -7%;
    max-width: 400px;
  }

  @media (max-width: 400px) {
    width: 300px;
    left: -20%;
  }

  top: 58px;
  z-index: 10000;
  position: absolute;
  left: 20%;

  .notifications-bottom-row {
    a {
      color: #409eff;
      cursor: pointer;
    }

    padding: 11px 0px;
    text-align: center;
    background-color: white;
  }
  .load-more {
  padding: 10px 10px;
  text-align: center;
  a {
      color: #409eff;
      cursor: pointer;
    }
  }
  .load-more-element {
    cursor: pointer;
  }
  .notifications-top-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    span {
      color: #000;
      font-weight: 500;
    }

    a {
      color: #409eff;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .first-row-select {
    border-bottom : none;
    padding:5px 10px 0px 10px;
  }

  .arrow {
    width: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 0px;
    border-bottom: 10px solid white;
    margin-left: 62%;
    margin-top: 14px;
  }

  .el-scrollbar {
    border: 1px solid #e3eafd;
    border-radius: 5px;
  }

  .list-group-flush {
    padding: 0px;
    background: white;

    &>.list-group-item {
      border-width: 0 0 1px;
    }
  }

  .noNotifications {
    margin-left: 40%;
    display: grid;
    gap: 0px;

    .img-text {
      margin-left: 5px;
      color: #1f1f1f;
    }
  }

  .notifcation-item {
    cursor: pointer;
    padding: 10px 20px;
    display: grid;
    grid-template-columns: 2.75em 1fr;
    gap: 15px;

    @media (max-width: 499.98px) {
      // display: grid;
      padding: 5px 5px !important;
    }

    .item-avatar {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #f754a2;
      background-color: rgba(#f754a2, $alpha: 0.1);
      color: #f754a2;

      .unread {
        position: absolute;
        z-index: 10000;
        width: 7px;
        height: 7px;
        top: 9px;
        left: 28px;
        border-radius: 8px;
        background: #40a0ff;
      }
    }

    .item-body {
      display: flex;
      justify-content: space-between;

      p {
        font-size: 13px;
        font-style: italic;
      }

      .name {
        font-style: normal;
        margin-bottom: 0;
        color: #6c757d !important;
        min-height: 21px;
        font-weight: 500;
        font-size: 15px !important;
      }

      .date-time {
        font-style: normal;
        min-width: 100px;

        @media (max-width: 499.98px) {
          min-width: 70px !important;
        }

        text-align: right;
        color: rgba(0, 0, 0, 0.5) !important;
        font-size: 12px;
      }

      .doc-name {
        font-style: normal;
        font-size: 13px !important;
        font-weight: 500;
        color: gray;
        min-width: 20px;
        margin-left: 5px;
      }
    }

    @media (max-width: 499.98px) {
      // display: list-item;
    }
  }
}
.notification-popup2 {
  left : 26%;
}

.workspace-wrapper {
  position: relative;
  margin-right: 30px;
  cursor: pointer;

  @media (max-width: 500px) {
    max-width: 130px;
  }

  @media (max-width: 400px) {
    max-width: 100px;
    margin-right: 20px;
  }
}

.top-navbar-right-menu {
  @media (max-width: 500px) {
    .user-image {
      &.profile-image {
        display: none;
      }
    }
  }

  .user-role {
    @media (max-width: 500px) {
      text-align: left;
      padding-left: 0px;
    }

    padding-left: 27px;
  }
}

.el-message-box__wrapper {
  .el-message-box {
    @media (max-width: 420px) {
      width: 95% !important;
    }
  }
}

.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: var(--primary-color);
    color: var(--primary-contrast-color) !important;
}

.el-select-dropdown__item.selected {
    color: var(--primary-color);
    font-weight: 700;
}
</style>
